.Room {
  grid-template-rows: repeat(4, min-content);
  grid-row-gap: 1rem;
  row-gap: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  height: 100%;

  .From {
    grid-row-start: 2;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: saturate(180%) blur(1px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    @media screen and (prefers-color-scheme: dark) {
      background: rgba(0, 0, 0, 0.7);
    }
  }
  .pock-container {
    height: 100%;
    padding: 8px 5rem;
    background-image: url('../../pages//Landing/glogo.png');
    background-repeat: no-repeat;
    background-size: 30% auto;
    background-position: center;
  }
  .pock-backdrop {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
  }

  @media (max-width: 500px) {
    .pock-container {
      padding: 8px 1rem;
    }
  }
}
