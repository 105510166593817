.Share {
  display: flex;
  line-height: 1.5em;
  white-space: nowrap;
  margin-left: 12px;
  margin-top: 8px;

  h1 {
    margin-right: 0.5rem;
    font-size: 1.5em;
    font-weight: 100;

    strong {
      font-weight: 600;
    }

    @media screen and (prefers-color-scheme: dark) {
      color: rgb(254, 255, 254);
    }
  }

  input {
    position: absolute;
    left: -99999px;
  }

  a {
    border-bottom: 2px solid;
    border-bottom-color: inherit;
    color: inherit;
    text-decoration: none;
  }

  [aria-label] {
    position: relative;
  }

  @media (min-width: 768px) {
    [aria-label]:hover:before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: -1rem;
      margin-top: -0.15rem;
      width: 0.5rem;
      height: 0.5rem;
      background: black;
      transform: rotate(45deg);
    }

    [aria-label]:hover:after {
      content: attr(aria-label);
      position: absolute;
      margin-left: 0.7rem;
      padding: 8px 12px;
      border-radius: 3px;
      background: black;
      font-size: 14px;
      color: white;
      white-space: nowrap;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      line-height: 18px;
    }
  }

  @media (min-width: 500px) {
    [aria-label]:active:before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: -1rem;
      margin-top: -0.15rem;
      width: 0.5rem;
      height: 0.5rem;
      background: black;
      transform: rotate(45deg);
    }

    [aria-label]:active:after {
      content: attr(aria-label);
      position: absolute;
      margin-left: 0.7rem;
      padding: 8px 12px;
      border-radius: 3px;
      background: black;
      font-size: 14px;
      color: white;
      white-space: nowrap;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      line-height: 18px;
    }
  }

  @media screen and (prefers-color-scheme: dark) {
    [aria-label]:hover:before {
      background: rgb(215, 215, 216);
    }

    [aria-label]:hover:after {
      background: rgb(215, 215, 216);
      color: black;
    }
  }
}
