.Join {
  --join-font-size: 1.5em;
  --join-button-size: 2em;
  --join-button-border-radius: calc(var(--join-button-size) / 5);

  /*  margin: 1rem 0;
  text-align: center;
  font-size: var(--join-font-size);
  white-space: nowrap; */

  --button-size: 3rem;
  --button-border-radius: calc(var(--button-size) / 5);

  display: grid;
  grid-template-rows: 1fr auto 1fr 3rem;
  height: 100vh;
  background-size: auto 100%;
  background-position: bottom;
  background-color: darkgrey;
  /*background-image: url('../../pages/Landing/glogo.png');*/

  @media screen and (max-width: 500px) {
    background-image: url('../../pages/Landing/glogo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .Form {
    grid-row-start: 2;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: saturate(180%) blur(1px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);


  }

  h1 {
    font-size: 3rem;
    font-weight: 100;

    strong {
      font-weight: 600;
    }


  }
  h1,
  p {
    margin: 1rem 0;
    text-align: center;
  }

  .collect-play-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .collect-play-name-container span.text {
    font-size: 18px;
    font-weight: 600;
    color: rgba(70, 71, 101, 1);
  }

  button {
    margin-left: 0.6rem;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    border-radius: var(--join-button-border-radius);
    color: white;
    outline: none;

    @media (min-width: 768px) {
      &:hover {
        transform: scale(1.2);
      }

      &:active {
        transform: scale(1.1);
      }
    }
  }

  input[type='text'] {
    width: 9em;
    margin: 0 1.5rem;
    border: none;
    border-bottom: 2px solid;
    border-bottom-color: inherit;
    background: transparent;
    text-align: left;
    font-family: inherit;
    font-size: 18px;
    outline: none;
    transform: scale(1.2);
    transform-origin: bottom;
  }
}

@media (max-width: 500px) {
  .Join {
    --join-font-size: 1.4em;
  }
  .Join input[type='text'] {
    width: 8em;
    margin: 0 0.2rem;
    transform: none;
  }
  .Join .Form {
    padding: 0.5rem;
  }
}

@media (max-width: 374px) {
  .Join {
    --join-font-size: 1.1em;
  }
}
