.Actions {
  --actions-font-size: 1.5em;
  --actions-button-size: 2em;
  --actions-button-border-radius: calc(var(--actions-button-size) / 5);

  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 1rem 0;
  font-size: var(--actions-font-size);

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style-type: none;
    transition: transform 0.2s ease;
  }

  button {
    position: relative;
    display: inline-block;
    height: var(--actions-button-size);
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    font-family: inherit;
    font-size: inherit;
    outline: none;

    &.selected::after {
      content: '';
      position: absolute;
      bottom: 0.2em;
      left: 50%;
      width: 4px;
      height: 4px;
      margin-left: -2px;
      border-radius: 50%;
      background-color: white;
    }

    @media (min-width: 768px) {
      &:hover {
        transform: scale(1.2);
      }

      &:active {
        transform: scale(1.1);
      }
    }
  }

  .scores-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .scores {
    flex-grow: 0.2;
    margin-bottom: 1rem;
    background-image: linear-gradient(45deg, #feac5e, #d180da, #4bc0c8);
    border-radius: var(--actions-button-border-radius);
    overflow: hidden;
    box-shadow: 0px 2px 8px 0px #d4c4b4, 0px 2px 4px 0px #a8c6c8;
  }

  .scores button {
    width: var(--actions-button-size);
    background: transparent;
    color: white;
  }

  .operations {
    position: absolute;
    right: 0;
  }

  .operations li {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }

  .operations button {
    width: calc(var(--actions-button-size) * 2);
    border-radius: var(--actions-button-border-radius);
    background: rgba(var(--v-bg-action));
    color: white;
    border-radius: 9999px;

    &.danger {
      background: crimson;
    }
  }
}

@media (max-width: 1000px) {
  .Actions {
    .operations {
      position: relative;
    }
  }
}

@media (max-width: 850px) {
  .Actions {
    display: block;

    .operations {
      justify-content: space-between;
    }

    .operations li {
      position: relative;
    }
    .operations li {
      flex-grow: 1;
    }

    .operations button {
      width: 100%;
    }
  }
}
