.Summary {
  --summary-item-font-size: 1.5em;
  --summary-item-width: 60px;
  --summary-item-height: 150px;

  font-size: var(--summary-item-font-size);
  transition: opacity 500ms ease;

  &.fade-enter,
  &.fade-exit {
    opacity: 0.01;
  }

  &.fade-enter-active {
    opacity: 1;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
  }

  li {
    margin: calc(var(--summary-item-width) / 10);
    margin-bottom: 1em;
    text-align: center;
    user-select: none;
  }

  dt {
    margin-top: -1.5em;
    white-space: nowrap;
    color: DarkSlateGray;

    small {
      display: block;
      font-size: 0.75em;
      margin-bottom: -1.5em;
      transform: translateY(calc(-1 * var(--summary-item-height) + 3em));
    }
  }

  dd {
    display: flex;
    width: var(--summary-item-width);
    height: var(--summary-item-height);
    margin: 0;
    color: DarkSlateGray;
    background-image: linear-gradient(
      to bottom,
      rgba(var(--v-bg-action)),
      rgba(142, 223, 249, 1)
    );
    border-radius: calc(var(--summary-item-height) / 10);
    overflow: hidden;

    i {
      flex-grow: 1;
      height: 100%;
      background-color: #f4f6f6;
      transition: max-height 500ms ease-out;
    }
  }
}

@media (max-width: 500px) {
  .Summary {
    --summary-item-width: 40px;
    --summary-item-height: 100px;
  }
}
