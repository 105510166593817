.SpeechBallon {
  --text-color: white;
  --bg-color: rgb(0, 124, 252);

  &.green {
    --bg-color: yellowgreen;
  }

  &.red {
    --bg-color: crimson;
  }

  position: absolute;
  top: -1em;
  left: 67%;
  margin: 0;
  padding: 1em;
  border-radius: 1.5em;
  background-color: var(--bg-color);
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  color: var(--text-color);
  white-space: nowrap;

  &::before {
    --triangle-size: 1em;

    content: ' ';
    position: absolute;
    bottom: calc(var(--triangle-size) * -0.6);
    left: 0;
    width: 0;
    height: 0;
    border-width: var(--triangle-size);
    border-left-width: calc(var(--triangle-size) * 1.5);
    border-style: solid;
    border-color: transparent;
    border-left-color: var(--bg-color);
    transform: rotate(20deg);
  }

  &.popin-enter {
    transform: scale(0.01);
    transform-origin: bottom left;
  }

  &.popin-enter.popin-enter-active {
    transform: scale(1);
    transition: transform 300ms cubic-bezier(.45,.45,.42,1.36);
  }

  &.popin-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.popin-exit.popin-exit-active {
    opacity: 0;
    transform: scale(1.2);
    transition: all 300ms ease-in;
  }
}
