.Card {
  --card-height: 150px;
  --card-width: 100px;

  position: relative;
  width: var(--card-width);
  height: var(--card-height);
  font-size: calc(var(--card-height) / 3);
  line-height: var(--card-height);
  transition: all 0.5s ease;
  opacity: 0.7;
  perspective: calc(var(--card-height) * 2);

  .face {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform 500ms ease;
    text-align: center;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    background-color: white;
    box-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.2);
    border-radius: calc(var(--card-height) / 10);
    color: DarkSlateGray;
  }

  .back.face {
    color: LightSteelBlue;
    background-image: linear-gradient(130deg, LightSteelBlue, SteelBlue);
  }

  .front.face {
    background-image: linear-gradient(130deg, white, LightGray);
    transform: rotateY(180deg);
  }

  &.voted {
    opacity: 1;
    .back.face {
        color: olivedrab;
        background-image: linear-gradient(130deg, gold, darkorange);
    }
  }

  &.bounce-enter.bounce-enter-active {
    animation: 1s ease-out 1 bounce;
  }

  &.show {
    .back.face {
      transform: rotateY(-180deg);
    }

    .front.face {
      transform: rotateY(0deg);
    }
  }

  &.highlight {
    transform: translateY(-0.2em) scale(1.1) rotate(3deg);
    color: steelblue;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(calc(var(--card-height) / -5));
  }
  40% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(calc(var(--card-height) / -10));
  }
  80% {
    transform: translateY(0);
  }
}

@media (max-width: 500px) {
  .Card {
    --card-height: 100px;
    --card-width: 67px;
  }
}
