.Votes {
  --vote-width: 100px;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    list-style-type: none;
  }

  li {
    min-width: 20%;
    margin: 0 0 1rem;
  }

  dd,
  dt {
    width: var(--vote-width);
    margin: 0 auto;
  }

  dd {
    position: relative;
  }

  dt {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 2em;
  }

  .fade-enter {
    opacity: 0.01;
    transform: scale(1.2);
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 500ms ease-in;
  }

  .fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transform: scale(1.2);
    transition: all 300ms ease-in;
  }
}
.SpeechBallon {
  z-index: 999;
}

@media (max-width: 500px) {
  .Votes {
    --vote-width: 67px;

    li {
      min-width: 25%;
    }
  }
}

@media (max-width: 374px) {
  .Votes {
    li {
      min-width: 33%;
    }
  }
}
