* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

.App {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 16px;


}

@media screen and (max-width: 500px) {
  body {
    font-size: 12px;
  }
}

input {
  border-radius: 0; /* reset for iOS */
  box-shadow: none; /* reset for Firefox (HTML5 form validation) */
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}

@media (max-width: 500px) {
  .github-corner svg {
    width: 50px;
    height: 50px;
  }
}
