.Landing {
  --button-size: 3rem;
  --button-border-radius: calc(var(--button-size) / 5);

  display: grid;
  grid-template-rows: 1fr auto 1fr 3rem;
  height: 100vh;
  background-size: auto 100%;
  background-position: bottom;
  background-color: darkgrey;
  /*background-image: url('./people-group.webp');*/

  @media screen and (max-width: 500px) {
    background-image: url('./glogo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media screen {
    background-blend-mode: multiply;
    background-color: DimGray;
  }

  .Form {
    grid-row-start: 2;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: saturate(180%) blur(1px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  }

  h1 {
    font-size: 3rem;
    font-weight: 100;

    strong {
      font-weight: 600;
    }


  }

  h1,
  p {
    margin: 1rem 0;
    text-align: center;
  }

  .router-link-input-label {
    position: absolute;
    top: 16px;
    left: 32px;
    font-size: 24px;
  }

  button {
    margin-left: 0.6rem;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    border-radius: var(--button-border-radius);
    color: white;
    outline: none;

    @media (min-width: 768px) {
      &:hover {
        transform: scale(1.2);
      }

      &:active {
        transform: scale(1.1);
      }
    }
  }

  .router-link-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .router-link-input {
    position: relative;
    display: flex;
    padding: 8px 12px;
    align-items: center;
  }

  .router-link-input .vega-input-input::before {
    content: '/';
    position: absolute;
    top: 0px;
    font-size: 18px;
  }

  input[type='text'] {
    margin-left: 0.5rem;
    border: none;
    background: transparent;
    color: inherit;
    text-align: left;
    font-family: inherit;
    font-size: inherit;
    outline: none;
  }

  .unsplash-credit {
    grid-row-start: 4;
    text-align: center;

    a {
      display: inline-block;
      background-color: black;
      color: white;
      text-decoration: none;
      padding: 4px 6px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.2;
      border-radius: 3px;

      span {
        display: inline-block;
        padding: 2px 3px;
      }

      svg {
        height: 12px;
        width: auto;
        position: relative;
        vertical-align: middle;
        top: -1px;
        fill: white;
      }
    }
  }
}
